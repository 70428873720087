import React from "react"
import { graphql, StaticQuery } from "gatsby"

import IndexLayout from "../components/index-layout"

import SEO from "../components/seo"
// import "../utils/global.scss"

import "../utils/normalize.css"
import "../utils/css/v2.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const PageIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  return (
    <IndexLayout
      location={location}
      title={siteTitle}
      headerActive={"Expertise"}
    >
      <SEO title="Home" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
    </IndexLayout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <PageIndex location={props.location} props data={data} {...props} />
    )}
  />
)
