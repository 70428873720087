import React from "react"
// import { Link } from "gatsby"

import Header from "./partials/header"

import ScrollingHorizontally from "../components/main-page-scroll"
// import ResponsiveMenu from "./responsive-menu"

class IndexLayout extends React.Component {
  render() {
    const { children, headerActive, location } = this.props

    return (
      <div className="site-wrapper site-wrapper-main-page" id="site-wrapper">
        <Header headerActive={headerActive} location={location}></Header>
        <div id="site-main">
          <ScrollingHorizontally></ScrollingHorizontally>
          {children}
        </div>
        {/* <main id="site-main" className="site-main">
          <div id="swup" className="animated fadeIn">
            <ExpertisePeek></ExpertisePeek>
          </div>
        </main> */}
      </div>
    )
  }
}

export default IndexLayout
